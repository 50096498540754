/* eslint-disable no-invalid-this */
import './productblocks.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  $('.js-expand-productblock').click(function(evt) {
    $(this).closest('.emb-block').toggleClass('expanded');
  });
});
