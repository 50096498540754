/* eslint-disable no-invalid-this */
/* global $ */
import './header.scss';

import * as dompack from 'dompack';

import './smartmenus/smartmenus';

dompack.onDomReady(() => {
  $('.header__language').mouseenter(() => {
    $(this).find('.header__language--choose').addClass('header__language--visible');
  });

  $('.header__language').mouseleave(() => {
    $(this).removeClass('header__language--visible');
  });
});
