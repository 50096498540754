.header {
  width: 100%;
  height: 195px;
  background: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 30px;
  left: 0;
  right: 0;
  z-index: 700;
  transition: height 0.3s;
}
html.filters-open .header {
  z-index: 0;
}
.header__inner {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
@media (max-width: 1200px) {
  .header__inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.header__topbar {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 15px 0 0;
  transition: transform 0.3s, opacity 0.3s;
}
.header__topbar .tempdisclaimer {
  position: relative;
  top: -15px;
}
@media screen and (max-width: 1200px) {
  .header__topbar .tempdisclaimer {
    padding: 10px 0 0 10px;
  }
}
@media screen and (max-width: 650px) {
  .header__topbar .tempdisclaimer {
    display: none;
  }
}
@media screen and (max-width: 650px) {
  .header__topbar {
    left: auto;
  }
}
.header__othersite {
  font-size: 10px;
  line-height: 100%;
  text-decoration: none;
  margin: 0 17px 0 0;
  font-weight: 700;
  color: #aca9a9;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  padding: 0 0 0 10px;
}
html.non-touch .header__othersite:hover {
  text-decoration: underline;
}
.header__searchform {
  position: relative;
}
.header__searchinput {
  font-size: 12px;
  line-height: 100%;
  border: 1px solid #aca9a9;
  border-radius: 23px;
  width: 209px;
  height: 46px;
  max-width: 100%;
  padding: 0 45px 0 20px;
  font-family: Arial;
  font-weight: 700;
  color: #aca9a9;
  letter-spacing: 0.75px;
  outline: none;
}
.header__searchinput:focus {
  border-color: #6b8f00;
}
.header__searchinput::-webkit-input-placeholder {
  font-size: 10px;
  line-height: 100%;
  color: #aca9a9;
  text-transform: uppercase;
}
.header__searchinput:-moz-placeholder {
  font-size: 10px;
  line-height: 100%;
  color: #aca9a9;
  text-transform: uppercase;
}
.header__searchinput::-moz-placeholder {
  font-size: 10px;
  line-height: 100%;
  color: #aca9a9;
  text-transform: uppercase;
}
.header__searchinput:-ms-input-placeholder {
  font-size: 10px;
  line-height: 100%;
  color: #aca9a9;
  text-transform: uppercase;
}
.header__searchicon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 2px;
  right: 10px;
  border: 0;
  outline: none;
  cursor: pointer;
  background: none;
}
.header .logo__image {
  transition: height 0.3s;
}
.header .logo__subtext {
  transition: font-size 0.3s;
}
.header__mt-top-bar {
  height: 15px;
  display: flex;
  position: relative;
  background-repeat: repeat;
}
.header__mt-top-bar::before {
  background-image: linear-gradient(-180deg, #bbcf2f 0%, #85a215 100%);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  z-index: 1;
  bottom: 0;
}
.header__languages {
  display: none;
}
html.mt.smallheader .header__languagescontainer {
  transform: translate(-50%, -100px);
}
@media screen and (max-width: 480px) {
  html.mt.smallheader .header__languagescontainer {
    transform: translate(-50%, 6px);
  }
}
html.mt .header {
  box-shadow: 0 2px 6px 0 rgba(111, 112, 109, 0.5);
}
html.mt .header__languagescontainer {
  pointer-events: none;
  width: 100vw;
  max-width: 1200px;
  position: fixed;
  z-index: 5000;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
}
html.mt .header__searchform {
  padding-right: 50px;
}
html.mt .header__searchicon {
  right: 60px;
}
html.mt .header__topbar {
  justify-content: flex-end;
}
html.mt .header__languages {
  display: flex;
  position: absolute;
  right: 0;
  margin-left: 15px;
  top: 10px;
  pointer-events: auto;
}
html.mt .header__language {
  position: relative;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  display: flex;
  top: 5px;
}
html.mt .header__language--choose {
  display: none;
  position: absolute;
  top: 25px;
}
html.mt .header__language--downarrow {
  position: relative;
  top: -4px;
}
html.mt .header__language:hover .header__language--choose {
  display: flex;
  flex-direction: column;
}
html.mt .header__language--link {
  text-decoration: none;
  margin-bottom: 10px;
  background: #ffffff;
}
html.mt .header__language--flag {
  width: 24px;
  height: 16px;
}
@media screen and (max-width: 1200px) {
  html.mt .header__languages {
    padding-right: 20px;
  }
}
@media screen and (max-width: 730px) {
  html.mt .header {
    box-shadow: none;
  }
  html.mt .header__inner {
    box-shadow: 0 2px 6px 0 rgba(111, 112, 109, 0.5);
  }
}
html.smallheader .header {
  height: 100px;
  padding-bottom: 0;
}
html.smallheader .header__inner {
  align-items: center;
}
html.smallheader .header__topbar {
  transform: translateY(-100px);
  opacity: 0;
  pointer-events: none;
}
html.smallheader .header .logo__image {
  height: 45px;
}
html.smallheader .header .logo__subtext {
  font-size: 12px;
}
html.mt.smallheader .header .logo {
  margin-top: -15px;
}
@media screen and (max-width: 1200px) {
  .header__topbar {
    padding-right: 25px;
  }
}
@media screen and (max-width: 730px) {
  .header__inner {
    align-items: center;
    background: #fff;
  }
  .header .logo__image {
    height: 58px;
  }
}
@media (min-width: 481px) and (max-width: 730px) {
  html.mt .header .logo__image {
    margin-top: 44px;
  }
  html.mt.smallheader .header .logo__image {
    margin-top: 16px;
  }
}
@media (max-width: 480px) {
  .header {
    height: 80px;
  }
  .header__topbar {
    display: none;
  }
  .header .logo__image {
    height: 40px;
  }
  .header .logo__subtext {
    font-size: 11px;
  }
}

.topbar {
  background-color: #000;
  height: 30px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 900;
}
.topbar .topbar__container {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .topbar .topbar__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.topbar .phone {
  display: flex;
  gap: 0.5rem;
}
.topbar .phone a {
  font-size: 16px;
  font-family: "din-2014-narrow", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  text-decoration: none;
}
.topbar .slootsmid-reviews {
  display: flex;
  align-items: center;
  justify-content: center;
}
.topbar .slootsmid-reviews .rating-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 0.3rem;
}
.topbar .slootsmid-reviews .rating-container a {
  color: #fff;
  margin-left: 0.4rem;
  font-size: 16px;
  font-family: "din-2014-narrow", sans-serif;
  font-weight: 400;
  font-style: normal;
}
html.non-touch .topbar .slootsmid-reviews .rating-container a:hover {
  text-decoration: none;
}
.topbar .slootsmid-reviews .rating-container .rating {
  margin-right: 0.4rem;
  color: #fff;
  font-size: 16px;
  font-family: "din-2014-narrow", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.topbar .website-switcher {
  display: flex;
}
.topbar .website-switcher a {
  background-color: transparent;
  color: #fff;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 20px;
  font-family: "din-2014-narrow", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-decoration: underline;
  display: inline-block;
}
.topbar .website-switcher a.active {
  background-color: #fff;
  color: #1a1a1a;
  text-decoration: none;
  pointer-events: none;
}
@media screen and (max-width: 640px) {
  .topbar .website-switcher {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .topbar .topbar__container {
    justify-content: center;
  }
  .topbar .phone {
    display: none;
  }
}