import "./home.scss";

import * as dompack from "dompack";

import DealerLocator from "../../dealer_locator/dealer_locator";

window.loadMaps = function () {
  new DealerLocator({ mapContainer: dompack.qS(".p-dealers__map") });
};

function loadAPI() {
  let script = document.createElement("script");
  script.src =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyBr1KKWkjeThQEq2gj0Uw_HC9OZcQ8VVKM&callback=loadMaps";
  script.type = "text/javascript";
  document.getElementsByTagName("head")[0].appendChild(script);
}

let googleMapsLoaded = false;
dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains("page-home")) return;

  const elMap = dompack.qS(".p-dealers__map");
  console.log("🚀 ~ file: home.es:32 ~ observer ~ elMap", elMap);
  if (elMap) {
    // interaction observer
    let observer = new IntersectionObserver((entries) => {
      entries.forEach(function (entry) {
        if (googleMapsLoaded) return; // already loaded the maps
        if (entry.isIntersecting) {
          console.log("map is visible");
          googleMapsLoaded = true;
          loadAPI();
        }
      });
    });
    observer.observe(elMap);
  }
});
