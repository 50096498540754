/* global $ */
import "./logoslider.scss";

import * as dompack from "dompack";

let animationSpeedFactor = 1.6; // higher is slower

dompack.onDomReady(() => {
  // assuming at most one logoslider
  let $logoSlider = $(".logoslider").first();
  if ($logoSlider.length == 0) {
    return;
  }

  let numSlides = $logoSlider.find(".slide").length;

  let $slideTrack = $logoSlider.find(".slide-track");
  $slideTrack.css("width", `calc(250px * ${numSlides}`);

  // add keyframe CSS
  let style = document.createElement("style");
  style.type = "text/css";

  let keyFrames = `
  @keyframes scroll {
      0% { transform: translateX(0); }
      100% { transform: translateX(calc(-250px * ${numSlides}))}
  }`;
  style.innerHTML = keyFrames;
  document.getElementsByTagName("head")[0].prepend(style);

  // apply the animation using keyframe CSS
  let animationSpeed = `${numSlides * animationSpeedFactor}s`;
  $slideTrack.css("animation", `scroll ${animationSpeed} linear infinite`);
});
