/* eslint-disable no-invalid-this */
/* global $ */
// require('./jquery.smartmenus.min.js');
import './sm-core-css.css';
import './sm-clean.scss';

import './smartmenus.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  $('#main-menu').smartmenus({
    mainMenuSubOffsetX: -1,
    mainMenuSubOffsetY: 4,
    subMenusSubOffsetX: 6,
    subMenusSubOffsetY: -6,
    // hideTimeout: 600000,
  });

  let $mainMenuState = $('#main-menu-state');
  if ($mainMenuState.length) {
    // animate mobile menu
    $mainMenuState.change(function(e) {
      let $menu = $('#main-menu');
      if (this.checked) {
        $menu.hide().slideDown(250, function() { $menu.css('display', ''); });
      } else {
        $menu.show().slideUp(250, function() { $menu.css('display', ''); });
      }
    });
    // hide mobile menu beforeunload
    $(window).bind('beforeunload unload', function() {
      if ($mainMenuState[0].checked) {
        $mainMenuState[0].click();
      }
    });
  }

  // $('#main-menu').on('mouseenter.smapi', function(e, item) {
  //   if (e.namespace == 'smapi') {
  //     $(document.documentElement).addClass('menu-open');
  //   }
  // });

  // $('#main-menu').on('hide.smapi', function(e, item) {
  //   if (e.namespace == 'smapi') {
  //     $(document.documentElement).removeClass('menu-open');
  //   }
  // });

});
