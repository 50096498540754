/* global $ */
/* eslint-disable no-invalid-this */
/* global lightGallery */
import "./occasions.scss";

import * as dompack from "dompack";
import * as util from "../../shared/js/utilities";
import * as whintegration from "@mod-system/js/wh/integration";

// vendor
// import $ from "jquery";
// window.$ = $;
// require("../../lightgallery/js/lightgallery.min.js");
// require("../../lightgallery/js/lg-thumbnail.min.js");
// require("../../lightgallery/js/lg-fullscreen.min.js");
// require("../../lightgallery/js/lg-zoom.min.js");
import * as modal from "./modal/modal";

// import Sticky from "sticky-js";

dompack.onDomReady(() => {
  $(".p-occasionspage .js-expand-category").click(function (evt) {
    evt.preventDefault();
    $(this).closest(".sidebarmenu").toggleClass("expanded");
  });

  if (document.documentElement.classList.contains("page-occasions")) {
    initOccasionsPage();
  }

  if (document.documentElement.classList.contains("page-occasion")) {
    initOccasionPage();
  }
});

function initOccasionsPage() {
  initLazyLoading();
  // if ($(window).width() > util.bpFilterSidebar) {
  //   new Sticky(".sticky");
  // }
}

function initOccasionPage() {
  let options = {
    thumbnail: true,
    selector: ".p-photos__photolink",
  };

  let lgElement = document.getElementById("occasionpage-photos");

  lgElement.addEventListener(
    "onAfterOpen",
    function (e) {
      $(".lg-backdrop").css("z-index", 25000);
      $(".lg-outer").css("z-index", 26000);
    },
    false
  );

  lightGallery(lgElement, options);

  setupInquireFormModal();
  $('input[name="occasion_name"]').val(whintegration.config.obj.ocassionname);
  $('input[name="occasion_url"]').val(whintegration.config.obj.ocassionurl);
}

async function setupInquireFormModal() {
  $(".js-show-exchangeinquire, .p-vacancydetails .card .card__button").click(
    async function (evt) {
      evt.preventDefault();
      await modal.runModal($("#exchangeinquire").get(0), {
        beforeOpen: function (dialog) {
          $(dialog).find(".modal").addClass("modal--exchangeinquire");
        },
        borrow: "#exchangeinquire",
      });
    }
  );
}

function initLazyLoading() {
  let options = {
    loadInvisible: true,
    successClass: "b-lazy-loaded",
  };

  if (dompack.debugflags["debug"]) {
    options.success = function (ele) {
      console.log("success", ele);
    };
    options.error = function (ele, msg) {
      console.log("error", ele, msg);
    };
  }

  new Blazy(options);
}
