/* global google */

import './dealer_locator.scss';

import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import domtemplate from '@mod-webwerf_sites/js/deprecated/template';
import { moveLatLng } from "./geo";
import * as util from '../shared/js/utilities';
import rpc from '../shared/js/services.rpc.json';

var map;
var searchResultsControl;
var dealerIcon;
// var clusterIcon;
var infoWindow;
// var dealerInfoWindow;
var geocoder;
var activemarkers = [];
var markerdataloaded = false;
var __updateleftpanel  = false;
var __referencelatlng = null;
var __querycheck = "";

const range = 15; // Search within 15 km of user location
// const maxResults = 15;
// const resultsPerPage = 4;

// let firstSearchDealersWithinBoundsCall = false;

export default class DealerLocator {
  constructor(options) {
    let container = options.mapContainer;//document.getElementById('google-maps-container');
    // if (container && container.parentNode.dataset.dealerIcon)
    //   dealerIcon = { url: container.parentNode.dataset.dealerIcon, scaledSize: new google.maps.Size(23, 41) };

    // clusterIcon = { url: whintegration.config.imgroot + (whintegration.config.site.isavon ? 'avon-cluster.2x.png' : 'cooper-cluster.2x.png')
    //               , scaledSize: new google.maps.Size(23, 41)
    //               , labelOrigin: new google.maps.Point(11,12)
    //               };

    // let url = new URL(location.href);
    let center = { lat: 52.1270987, lng: 6.5263997 };

    // Initialize the map
    map = new google.maps.Map(container, {
      center,
      zoom: 12,
      // minZoom: 15,
      scrollwheel: false,
      clickableIcons: false,
    });

    // Map bounds are only available after the first 'bounds_changed' event, so we'll wait for that before doing the initial
    // search for the given position
    // google.maps.event.addListenerOnce(map, "bounds_changed", event => { searchDealersAroundPosition(map.getCenter(), parseInt(url.searchParams.get("dealer"))); });

    // Initialize the dealer info window
    // dealerInfoWindow = createDealerInfoWindow();

    // Initialize the search results map control
    let searchResultsDiv = document.createElement("div");
    searchResultsControl = new SearchResultsControl(searchResultsDiv, map);
    searchResultsDiv.index = 1;
    map.controls[google.maps.ControlPosition.LEFT_CENTER].push(searchResultsDiv);

    // Initialize the Geocoder for text search
    geocoder = new google.maps.Geocoder();
    let searchForm = document.getElementById("searchform");
    searchForm.addEventListener("submit", handleSearch);

    fullSearch();

    // let mapMarkerUpdateTimer;
    // google.maps.event.addListener(map,'bounds_changed', function(){
    //   if( !markerdataloaded )
    //     return;
    //   clearTimeout(mapMarkerUpdateTimer);
    //   mapMarkerUpdateTimer = setTimeout(function(){ searchDealersWithinBounds(0, false); },300);
    // });
  }
}

async function fullSearch() {
  let dealers = await rpc.GetAllDealers(whintegration.config.obj.pageid);
// console.log(result);
  // populateMarkersByRPCResult(result, 0, true);

  let bounds  = new google.maps.LatLngBounds();
  for (const dealer of dealers) {
    // add location to bounds for auto fit/zoom/pan
    let loc = new google.maps.LatLng(dealer.lat, dealer.lng);
    bounds.extend(loc);
  }

  map.fitBounds(bounds);
  map.panToBounds(bounds);

  populateMarkersByRPCResult({ success: true, markers: dealers }, 0, true);
  searchResultsControl.showResults(dealers, 0);

  // google.maps.event.addListenerOnce(map, "bounds_changed", event => { searchDealersWithinBounds(0, true); });
}

// The Google Maps 'control' showing the search results
class SearchResultsControl
{
  constructor(div, map)
  {
    this.control = document.getElementById("dealerresults");

    this.container = this.control.querySelector(".p-dealerresults__container");
    this.container.addEventListener("click", event => this.onClickResult(event));

    // this.navPrev = this.control.querySelector(".p-dealerresults-prev");
    // this.navPrev.addEventListener("click", event => this.onPrevPage(event));
    // this.navNext = this.control.querySelector(".p-dealerresults-next");
    // this.navNext.addEventListener("click", event => this.onNextPage(event));
    // this.navCount = this.control.querySelector(".p-dealerresults-count");

    this.map = map;
    this.resultDivs = [];
  }

  showResults(results, selectId)
  {
    // Clear markers and result divs array

    this.resultDivs = this.resultDivs.filter(div =>
    {
      return false;
    });

    dompack.empty(this.container);

    if (results && results.length)
    {
      // this.navPrev.parentNode.style.display = "";

      let selectDiv = null;
      // Create divs for all results
      for (let result of results)
      {
        result.distance = util.formatDistance(result.distance);

        let resultDiv = domtemplate.instantiate(document.getElementById("dealer-result"), result).firstChild;
        if (result.id === selectId)
          selectDiv = resultDiv;
        resultDiv.marker = result.marker;
        this.resultDivs.push(resultDiv);
      }
      // Show the first page of results
      this.showResultsPage(0);
      this.control.style.display = "block";
      google.maps.event.trigger(this.map, "resize");

      if (selectDiv)
        this.showDealerInfoWindow(selectDiv);
    }
    else
    {
      // No results to show, hide full control for cooper
      //  if avon (which has category filtering, only hide prev/next
      // if( !whintegration.config.site.isavon )
      //   this.control.style.display = "none";
      // else
        // this.navPrev.parentNode.style.display = "none";
    }
  }

  showResultsPage(page)
  {
    this.container.textContent = "";
    for (let i = 0; i < this.resultDivs.length; ++i)
    {
      this.resultDivs[i].classList.remove("p-dealerresult--selected");
      this.container.appendChild(this.resultDivs[i]);
    }

    // this.curPage = page;
    // let numPages = Math.ceil(this.resultDivs.length / resultsPerPage);
    // this.navCount.textContent = `${this.curPage + 1}/${numPages}`;

    // $(this.navPrev).toggleClass('show', this.curPage > 0);
    // $(this.navNext).toggleClass('show', this.curPage < (numPages - 1));
  }

  // onPrevPage(event)
  // {
  //   event.preventDefault();
  //   this.showResultsPage(this.curPage - 1);
  // }

  // onNextPage(event)
  // {
  //   event.preventDefault();
  //   this.showResultsPage(this.curPage + 1);
  // }

  onClickResult(event)
  {
    if (event.target.classList.contains('button'))
      return;

    event.preventDefault();
    this.showDealerInfoWindow(dompack.closest(event.target, ".p-dealerresults__data"));
  }

  showDealerInfoWindow(dealer)
  {
    if (dealer)
      doShowDealerInfoWindow(dealer.dataset.id, 'list', false);
  }
}

function doShowDealerInfoWindow(dealerid, source, force, dealerdata)
{
  dealerid = parseInt(dealerid);

  // if( dealerInfoWindow.dealerId == dealerid )
  //  return;//already open

  let dealerlistnode = dompack.qS(`.p-dealerresults__data[data-id="${dealerid}"]`);
  let latlng;
  if( source == "map" )
    latlng = new google.maps.LatLng(dealerdata.lat, dealerdata.lng);
  else if(dealerlistnode) {
    latlng = new google.maps.LatLng(dealerlistnode.dataset.lat, dealerlistnode.dataset.lng);
  }

  // Slide the position into view
  map.panTo(latlng);

  // let infonode = dealerInfoWindow.setDealerInfo( dealerid, latlng, force);
  // dealerInfoWindow.setMap(map);

  // Highlight the selected dealer in the results list
  for (let result of document.querySelectorAll(".p-dealerresult"))
  {
    if ( parseInt(result.dataset.id) == dealerid )
      result.classList.add("p-dealerresult--selected");
    else
      result.classList.remove("p-dealerresult--selected");
  }

  // find the correct dealer
  let dealer = activemarkers.find(x => x.id == dealerid);
  if (!dealer) {
    console.error(`dealer ${dealerid} not found`, activemarkers);
  }

  if (infoWindow)
    infoWindow.close();

  infoWindow = new google.maps.InfoWindow({
    content: `
<div style="font-weight:bold;">
  ${dealer.title}<br />
  <br />
  <a href="${dealer.website}">Bezoek website</a>
</div>`,
    maxWidth: 500,
  });

  // and add to map
  infoWindow.open(map, dealer.marker);
}

async function handleSearch(event)
{
  if(event)
    dompack.stop(event);

  let searchform = document.getElementById("searchform");

  if (!document.getElementById("dealersearchinput").value) {
    searchform.classList.add("p-searchform--searching");
    await fullSearch();
    searchform.classList.remove("p-searchform--searching");
    return;
  }

  if (!searchform.classList.contains("p-searchform--searching"))
  {
    searchform.classList.add("p-searchform--searching");

    let searchstart = Date.now();
    let query = document.getElementById("dealersearchinput").value;
    let iszip = '0123456789'.split('').some(_ => query.indexOf(_) != -1);

    // Do a geocoder request for the entered address
    let results = await getGeocoderResult(query);
    let meta = { dn_loctime: Date.now() - searchstart
               , ds_loctype: iszip ? 'zip' : 'city'
               , ds_locquery: iszip ? '' : query
               , dn_locresults: results.length
               , ds_locsource: event ? 'button' : location.href.includes('source=product_detail') ? 'product_detail' : 'onload'
               };

    if(results.length >= 1)
    {
      //extract town and country from the first result
      let towncomp = results[0].address_components.find(_ => _.types.includes("postal_town"));
      let countrycomp = results[0].address_components.find(_ => _.types.includes("country"));
      if(towncomp && countrycomp)
      {
        meta.ds_loctown = towncomp ? towncomp.short_name : "";
        meta.ds_loccountry = countrycomp ? countrycomp.short_name : "";
      }
      meta.dn_loclat = Math.round(results[0].geometry.location.lat() * 10)/10;
      meta.dn_loclng = Math.round(results[0].geometry.location.lng() * 10)/10;
    }
    // datalayer.triggerEvent("coopertires:dealer_locationsearch", meta);

    if (results.length)
      searchDealersAroundPosition(results[0].geometry.location, 0, true);

    searchform.classList.remove("p-searchform--searching");
  }
}

// Promise wrapper around google.maps.Geocoder.geocode
function getGeocoderResult(address)
{
  let deferred = dompack.createDeferred();

  let geocodeOpts = { address, bounds: map.getBounds() };
  if (whintegration.config.obj.cctld)
    geocodeOpts.region = whintegration.config.obj.cctld;

  geocoder.geocode(geocodeOpts, (results, status) =>
  {
    if (status == google.maps.GeocoderStatus.OK || status == google.maps.GeocoderStatus.ZERO_RESULTS)
    {
      //Remove results not given in displaycountries
      if( whintegration.config.site.displaycountries && whintegration.config.site.displaycountries.length )
      {
        for( let i = results.length - 1; i >= 0; --i )
        {
          for( let c = results[i].address_components.length - 1; c >= 0; --c )
          {
            let rec = results[i].address_components[c];
            if( rec.types.indexOf("country") > -1 && whintegration.config.site.displaycountries.indexOf(rec.short_name) == -1 )
            {
              results.splice(i,1);
              break;
            }
          }
        }
      }

      deferred.resolve(results || []);
    }
    else
      deferred.reject(new Error(status));
  });

  return deferred.promise;
}

function searchDealersAroundPosition(position, selectId, updateleftpanel)
{
  __referencelatlng = position;

  // Calculate a range around the position
  let distance = range * 1000 / Math.sqrt(2);
  let ne = moveLatLng(position, 45, distance);
  let sw = moveLatLng(position, 225, distance);
  let bounds = new google.maps.LatLngBounds(sw, ne);

  // if (is_dealerpage)
  {
    // Fit the range bounds in the map and find dealers within the displayed bounds
    map.fitBounds(bounds);
    searchDealersWithinBounds(selectId, updateleftpanel);
    // scrollToMap();
  }
  return bounds; // The tire detail page just asks for the bounds
}

// Find dealers within the current bounds of the map
async function searchDealersWithinBounds(selectId, updateleftpanel)
{
  if( updateleftpanel )
    __updateleftpanel = updateleftpanel;//remember setting until rpc is finished (case rpc updates canceled)

  let bounds = map.getBounds();

  let param = { sw     : { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() }
              , ne     : { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() }
              , zoom   : map.getZoom()
              // , max    : maxResults
              , center : __updateleftpanel ? __referencelatlng : {}
              };

  //Prevent double submit
  let chk = JSON.stringify(param);
  if( chk == __querycheck )
    return;

  __querycheck = chk;

  if(rpc.activerequest)
  {
    for(var c = 0; c < rpc.requestqueue.length; c++)
      if(rpc.requestqueue[c].request.method == 'SearchDealersWithinBounds')
        rpc.requestqueue[c].cancel();//cancel active rpc request
  }

  let result = await rpc.SearchDealersWithinBounds(whintegration.config.obj.pageid, param.sw, param.ne, param.zoom, param.max, param.center );
  populateMarkersByRPCResult(result, selectId, __updateleftpanel);

  __updateleftpanel = false;
}

function populateMarkersByRPCResult(result, selectId, updateleftpanel) {
  // remove old markers
  for (const marker of activemarkers)
    marker.marker.setMap(null);

  if (result.success)
  {
    let currentmarkers = [];
    // let activeids = [];

    for (let dealer of result.markers)
    {
      // activeids.push(dealer.groupid);
      // let existingidx = -1;
      // for( var i = 0; i < activemarkers.length; ++i )
      // {
      //   if( activemarkers[i].groupid == dealer.groupid )
      //   {
      //     existingidx = i;
      //     break;
      //   }
      // }

      // if( existingidx == -1 )
      {
        // Create a marker for the result
        let latlng = new google.maps.LatLng(dealer.lat, dealer.lng);

        dealer.marker = new google.maps.Marker({
          position: latlng,
          map,
          animation: markerdataloaded ? null : google.maps.Animation.DROP,
          icon: dealerIcon,
          title: dealer.title,
          clickable: true
        });

        // Register a click handler for this result, showing the info for the selected dealer
        dealer.marker.addListener("click", event => doShowDealerInfoWindow(dealer.id, 'map', !!event.resultClick, dealer));

        currentmarkers.push(dealer);
      }
      // else
      //   currentmarkers.push( activemarkers.splice(existingidx, 1)[0] );
    }

    //Cleanup markers not in results
    // for( let i = activemarkers.length - 1; i >= 0; --i )
    // {
    //   activemarkers[i].marker.setMap(null);


    //   // //if has open infowindow, close it
    //   // if( dealerInfoWindow && activemarkers[i].id == dealerInfoWindow.dealerId )
    //   // {
    //   //   dealerInfoWindow.dealerId = null;
    //   //   dealerInfoWindow.anchorDiv.removeChild(dealerInfoWindow.dealerDiv);
    //   //   dealerInfoWindow.dealerDiv = null;
    //   // }
    // }

    // close any open infowindow
    // if (infoWindow)
    //   infoWindow.close();

    activemarkers = currentmarkers;

    markerdataloaded = true;

    if (updateleftpanel) {
      searchResultsControl.showResults(result.dealers, selectId);
    }
  }
  else
  {
    // Clear results
    searchResultsControl.showResults();
  }
}

// async function updateDealerInfoWindow( dealerid, source, infowindow )
// {
//   if(rpc.activerequest)
//   {
//     for(var c = 0; c < rpc.requestqueue.length; c++)
//       if(rpc.requestqueue[c].request.method == 'GetDealer')
//         rpc.requestqueue[c].cancel();//cancel active rpc request
//   }

//   let dealer = null;
//   if( dealerid )
//   {
//     dealer = await rpc.GetDealer(whintegration.config.obj.pageid, dealerid, __referencelatlng);

//     console.log(infowindow);
//     console.log(infowindow.dealerDiv);
//     dompack.empty(infowindow.dealerDiv);
//     if( dealer && dealer.id == infowindow.dealerId )
//     {
//       // datalayer.triggerEvent("coopertires:dealer_click", { dn_dealerid: dealerid
//       //                                                    , ds_dealer: dealer.title
//       //                                                    , ds_dealeraddress: dealer.displayaddress
//       //                                                    , ds_clicksource: source
//       //                                                    , db_dealerpremium: dealer.premium
//       //                                                    });

//       // Construct some links to use in the template
//       dealer.directions = `https://www.google.com/maps/dir/?api=1&destination=${dealer.lat},${dealer.lng}`;
//       dealer.mailto = dealer.email != "" ? `mailto:${dealer.email}` : "";

//       dealer.tel = dealer.phone ? "tel:" + dealer.phone : "";

//       // Format the distance (convert from meters to miles, add "mile(s)"/"km" suffix)
//       dealer.distance = util.formatDistance(dealer.distance);

//       infowindow.dealerDiv.appendChild( domtemplate.instantiate(document.getElementById("dealer-info"), dealer).firstChild );
//       // infowindow.dealerDiv.addEventListener("click", event =>
//       // {
//       //   let eventnameholder = dompack.closest(event.target, "*[data-cooper-clickevent]");
//       //   if(eventnameholder)
//       //     datalayer.triggerEvent(eventnameholder.dataset.cooperClickevent);
//       // }, true);

//       //Reposition/center infowindow
//       let containerpos = dompack.closest(infowindow.dealerDiv, "#google-maps-container").getBoundingClientRect();
//       let infopos = infowindow.dealerDiv.getBoundingClientRect();

//       let offsetbottom = containerpos.bottom - infopos.bottom;
//       let offsettop = infopos.top - containerpos.top - 50;
//       let offset = (offsetbottom - offsettop) / 2;
//       if( offset < -offsettop )
//         offset = -offsettop;
//       offsetMapCenter(0, -offset, infowindow.position);

//       infowindow.dealerDiv.querySelector(".p-dealerinfo__close").addEventListener("click", event =>
//       {
//         event.preventDefault();
//         infowindow.dealerId = null;
//         infowindow.anchorDiv.removeChild(infowindow.dealerDiv);
//         infowindow.dealerDiv = null;
//       });
//     }
//     else
//       dealer = null;
//   }

//   // if( !dealer )
//   // {
//   //   infowindow.dealerId = null;
//   //   if(infowindow.dealerDiv)
//   //   {
//   //     infowindow.anchorDiv.removeChild(infowindow.dealerDiv);
//   //     infowindow.dealerDiv = null;
//   //   }
//   // }
// }

// function offsetMapCenter(offsetx, offsety, latlng)
// {
//   // offsetx is the distance you want that point to move to the right, in pixels
//   // offsety is the distance you want that point to move upwards, in pixels
//   // offset can be negative

//   if(!latlng)
//     latlng = map.getCenter();

//   let scale = Math.pow(2, map.getZoom());

//   let worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
//   let pixelOffset = new google.maps.Point((offsetx/scale) || 0,(offsety/scale) || 0);

//   let worldCoordinateNewCenter = new google.maps.Point(
//     worldCoordinateCenter.x - pixelOffset.x,
//     worldCoordinateCenter.y + pixelOffset.y
//   );

//   let newcenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

//   //this.map.panTo(newcenter);
//   map.setCenter(newcenter);
// }
