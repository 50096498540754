/* global Swiper, $ */

import './photoalbum.scss';

import * as dompack from "dompack";
import * as util from '../shared/js/utilities';

let galleryThumbs;

dompack.onDomReady(() => {
  if ($('.photoalbum').length == 0)
    return;

  // $('.photoalbum__item').magnificPopup({
  //   type: 'image',
  //   tLoading: 'Loading image #%curr%...',
  //   mainClass: 'mfp-img-mobile',
  //   gallery: {
  //     enabled: true,
  //     navigateByImgClick: true,
  //     preload: [0,1] // Will preload 0 - before current, and 1 after the current image
  //   },
  //   image: {
  //     tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
  //   }
  // });

  let screenIsMd = util.testBreakPoint('md');

  $('.photoalbum').each((idx, el) => {

    let numSlides = $(el).find('.photoalbum__gallery-top .swiper-slide').length;

    let $galleryTop = $(el).find('.photoalbum__gallery-top');

    let galleryTopOptions = {
      navigation: {
        prevEl: screenIsMd ? $galleryTop.find('.js-photoalbum-mobile-prev').get(0) : $galleryTop.find('.js-photoalbum-prev').get(0),
        nextEl: screenIsMd ? $galleryTop.find('.js-photoalbum-mobile-next').get(0) : $galleryTop.find('.js-photoalbum-next').get(0),
      },
      loop: numSlides > 1,
      allowTouchMove: screenIsMd,
    };

    if (numSlides < 2) {
      $('.swiper-button-prev, .swiper-button-next, .photoalbum__gallery-bottom').remove();
      return;
    }

    if (screenIsMd) {
      if (numSlides < 2) {
        $galleryTop.find('.js-photoalbum-mobile-prev, .js-photoalbum-mobile-next').remove();
      }
    }
    else {
      galleryTopOptions.on = {
        slideChangeTransitionStart: function() {
          if (galleryThumbs) {
            galleryThumbs.slideToLoop(this.realIndex);
            $(galleryThumbs.slides).removeClass('is-selected');
            $(galleryThumbs.slides).eq(this.realIndex).addClass('is-selected');
          }
        }
      };
    }

    let galleryTop = new Swiper($galleryTop, galleryTopOptions);

    let $galleryThumbsContainer = $(el).find('.photoalbum__gallery-bottom');
    let $galleryThumbs = $(el).find('.photoalbum__thumbs');

    let $galleryThumbsPrev = $galleryThumbsContainer.find('.swiper-button-prev');
    let $galleryThumbsNext = $galleryThumbsContainer.find('.swiper-button-next');

    galleryThumbs = new Swiper($galleryThumbs, {
      spaceBetween: 10,
      slidesPerView: 'auto',
      loop: numSlides > 1,
      navigation: { prevEl: $galleryThumbsPrev, nextEl: $galleryThumbsNext },
      direction: 'horizontal',
      // slideToClickedSlide: true,
      allowTouchMove: false,
      on: {
        tap: function(swiper, event) {
          let indexSlideOnClick = $(this.clickedSlide).data('swiper-slide-index');
          galleryTop.slideTo(indexSlideOnClick + 1); // slideTo uses 1-based
        },
      }
    });

    $galleryThumbsNext.click(function() {
      galleryTop.slideNext();
      galleryThumbs.slideNext();
    });

    $galleryThumbsPrev.click(function() {
      galleryTop.slidePrev();
      galleryThumbs.slidePrev();
    });
  });
});
