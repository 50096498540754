import './promotions.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-promotions'))
    return;

  console.info('page: promotions');
});
