/* eslint-disable @typescript-eslint/no-invalid-this */
/* global $ */
/* eslint-disable no-invalid-this */
import './products.scss';

// WebHare
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import FilterGrid from '../../shared/js/filtergrid';

// vendor
// import Masonry from 'masonry-layout';
// require('../../web/js/masonry-plus.js');
// import Sticky from 'sticky-js';

dompack.onDomReady(() => {
  $('.p-productspage .js-expand-category').click(function(evt) {
    evt.preventDefault();
    $(this).closest('.sidebarmenu').toggleClass('expanded');
  });

  if (document.documentElement.classList.contains('page-categories'))
    initCategoriesPage();

  if (document.documentElement.classList.contains('page-products'))
    initProductsPage();

  // if (document.documentElement.classList.contains('page-product'))
  //   initProductDetailsPage();
});

function initCategoriesPage() {
  // let masonrySettings = {
  //   itemSelector: '.grid-item',
  //   columnWidth: '.grid-sizer',
  //   percentPosition: true,
  //   transitionDuration: 500,
  //   gutter: 18,
  //   horizontalOrder: true,
  // };

  // let grid = new Masonry('#products-grid', masonrySettings);
  // grid.layout();
  $('#products-grid').addClass('show');

  setupStickySiderBar();
}

function initProductsPage() {
  if (whintegration.config.obj.products.length > 0) {
    let filterGrid = new FilterGrid({ filterTypes: whintegration.config.obj.filters,
                                      products: whintegration.config.obj.products,
                                    });
    filterGrid.setup();
  }

  setupStickySiderBar();
}

// function initProductDetailsPage() {
// }

function setupStickySiderBar() {
  // if ($(window).width() > util.bpFilterSidebar) {
  //   new Sticky('.sticky');
  // }
}
