/* global google */

export function toRadians(degrees)
{
  return degrees * Math.PI / 180;
}

export function toDegrees(radians)
{
  return radians * 180 / Math.PI;
}

export function moveLatLng(latlng, direction, distance, radius = 6378137)
{
  let lat1 = toRadians(latlng.lat());
  direction = toRadians(direction);

  /*
  var φ2 = Math.asin( Math.sin(φ1)*Math.cos(d/R) + Math.cos(φ1)*Math.sin(d/R)*Math.cos(brng) );
  var λ2 = λ1 + Math.atan2(Math.sin(brng)*Math.sin(d/R)*Math.cos(φ1), Math.cos(d/R)-Math.sin(φ1)*Math.sin(φ2));
  */
  let lat2 = Math.asin(Math.sin(lat1) * Math.cos(distance / radius) + Math.cos(lat1) * Math.sin(distance / radius) * Math.cos(direction));
  let lng2 = Math.atan2(Math.sin(direction) * Math.sin(distance / radius) * Math.cos(lat1), Math.cos(distance / radius) - Math.sin(lat1) * Math.sin(lat2));

  lat2  = toDegrees(lat2);
  // Normalize into range -180 .. 180
  lng2  = latlng.lng() + toDegrees(lng2) + 540;
  while (lng2 > 360)
    lng2 = lng2 - 360;
  lng2 = lng2 - 180;

  return new google.maps.LatLng(lat2, lng2);
}
