/* eslint-disable getter-return */
/* global $ */
import * as dompack from "dompack";
import "dompack/browserfix/reset.css";
// import * as whintegration from '@mod-system/js/wh/integration';
// import * as gtm from '@mod-publisher/js/analytics/gtm.es';
// import * as dialog from 'dompack/components/dialog';
// import * as dialogapi from 'dompack/api/dialog';

// import rpc from './shared/js/services.rpc.json';
import * as util from "./shared/js/utilities";

import "./shared/forms/forms";
import "./shared/rtd/rtd";

import "slick-carousel/slick/slick.css";
// import 'magnific-popup/dist/magnific-popup.css';
// import 'sweetalert2/dist/sweetalert2.min.css';
// import swal from 'sweetalert2';
// import 'font-awesome/css/font-awesome.min.css';
// import Blazy from 'blazy';
// import "swiper/css/bundle";

import "./slootsmid.scss";

// components
import "./embeddedobjects/embeddedobjects";
import "./header/header";
import "./logoslider/logoslider";
import "./photoalbum/photoalbum";
import "./sendpdfform/sendpdfform";
import "./slideshow/slideshow";
import "./socialwall/socialwall";

// pages
import "./pages/home/home";
import "./pages/occasions/occasions";
import "./pages/news/news";
import "./pages/products/products";
import "./pages/promotions/promotions";
import "./pages/search/search";

import "./shared/css/print.scss";

let touchsupport =
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;
if (!touchsupport) {
  // browser doesn't support touch
  document.documentElement.className += " non-touch";
}

import { openLinksInNewWindow } from "@mod-publisher/js/linkhandler";
openLinksInNewWindow();

// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
// Test via a getter in the options object to see if the passive property is accessed
let supportsPassive = false;

let ua = window.navigator.userAgent;
let isInternetExplorer =
  ua.indexOf("MSIE ") > 0 ||
  Boolean(ua.match(/Trident.*rv:11\./)) ||
  document.documentElement.classList.contains("dompack--debug-isie");
if (isInternetExplorer) document.documentElement.classList.add("isie");

try {
  let opts = Object.defineProperty({}, "passive", {
    get: function () {
      supportsPassive = true;
    },
  });
  window.addEventListener("test", null, opts);
} catch (e) {}

// dialogapi.setupDialogs(options => dialog.createDialog('mydialog', options));

let smallerHeaderY = 0;

dompack.onDomReady(() => {
  // targetBlank();

  smallerHeaderY = $(window).height() / 6;
  window.addEventListener(
    "scroll",
    (ev) => onScroll(),
    supportsPassive ? { passive: true } : false
  );
  onScroll();

  // $('.js-download-pdf').click((evt) => {
  //   evt.preventDefault();
  //   openDownloadPDFDialog();
  // });

  setupFilterGrid();
});

function setupFilterGrid() {
  // normally we would put the contents of this function in filtergrid, but we need these for occasions overview too (which doesn't init filtergrid)
  $(".js-collapse-filter").on("click", function (e) {
    e.preventDefault();
    $("html").toggleClass("filters-open");
    e.stopPropagation();
  });

  // prevent clicks in overview filters container to reach <body>, which closes the filters
  $(".overviewfilters").click((evt) => {
    evt.stopPropagation();
  });

  $("body, .overviewfilters__close").click(() => {
    $("html").removeClass("filters-open");
  });

  if ($(window).width() <= util.bpFilterSidebar) {
    let $overviewFilters = $(".overviewfilters");
    if ($overviewFilters.length > 0) {
      dompack
        .qS("#mobileoverviewfilterscontainer")
        .appendChild($overviewFilters.get(0));
    }
  }
}

function onScroll() {
  let scrollY = util.getScrollY();
  $(document.documentElement).toggleClass("has-scrolled", scrollY > 0);

  if ($(window).width() > util.bpForceSmallHeader)
    $(document.documentElement).toggleClass(
      "smallheader",
      scrollY >= smallerHeaderY
    );
}

// async function openDownloadPDFDialog() {
//   gtm.sendEvent('openDownloadPopup');

//   const {value: email} = await swal.fire({
//     title: 'Ontvang pagina als PDF',
//     text: 'Vul hieronder je e-mailadres in om deze pagina als PDF te ontvangen.',
//     input: 'email',
//     confirmButtonText: 'Verzenden',
//     showCloseButton: true,
//     inputValue: dompack.debugflags['debug'] ? 'wouter@webwerf.nl' : '',
//     inputPlaceholder: 'E-mailadres in',
//     validationMessage: 'Ongeldig e-mailadres',
//   });

//   if (email) {
//     $('#loader').addClass('show');
//     try {
//       await util.addMinimumResolveTime(rpc.DownloadPDF(whintegration.config.obj.pageid, { email: email }), 2500);
//       gtm.sendEvent('sendDownloadPopup');
//       swal.fire('PDF-bestand verzonden', `Het PDF-bestand is succesvol verzonden naar ${email}.`);
//     }
//     catch(e) {
//       util.triggerGeneralErrorDialog(e);
//     } finally {
//       $('#loader').removeClass('show');
//     }
//   }
// }

// function targetBlank() {
//   // remove subdomain of current site's url and setup regex
//   let internal = location.host.replace("www.", "");
//   internal = new RegExp(internal, "i");

//   let a = document.getElementsByTagName("a"); // then, grab every link on the page
//   for (let i = 0; i < a.length; i++) {
//     let href = a[i].host; // set the host of each link
//     if (!internal.test(href)) {
//       // make sure the href doesn't contain current site's host
//       a[i].setAttribute("target", "_blank"); // if it doesn't, set attributes
//     }
//   }
// }
