/* global Swiper */
import "./carousel.scss";
import * as dompack from "dompack";

dompack.onDomReady(() => {
  dompack.qSA(".emb-carousel").forEach((slideshow) => {
    const elSlides = slideshow.querySelectorAll(".swiper-slide");
    if (!elSlides) {
      return;
    }

    slideshow.classList.add("slideshow--has-slides");

    new Swiper(slideshow.querySelector(".swiper-container"), {
      preloadImages: false,
      direction: "horizontal",
      loop: true,
      speed: 500,
      grabCursor: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    // $container.hover(
    //   function () {
    //     swiper.autoplay.stop();
    //   },
    //   function () {
    //     swiper.autoplay.start();
    //   }
    // );
  });
});
