import * as dompack from 'dompack';
import * as cookie from 'dompack/extra/cookie';
import * as utilities from '../js/utilities';

dompack.onDomReady(() => {
  if (shouldLoadVideos()) {
    loadIFrames();
  } else {

    let classNames = [".wh-video",".organizations__googlemap"];

    setupCookiewall(classNames);
  }
});

function shouldLoadVideos() {
  let cookieVal = cookie.read(utilities.getCookieBarCookieName());
  return cookieVal && cookieVal.includes('marketing');
}

window.refreshOnCookieWallChange = () => {
  if (shouldLoadVideos())
    loadIFrames();
};

function loadIFrames() {

  // Load iFrames (Youtube / Google Maps)
  require('@mod-publisher/js/richcontent/all');

  // for (const gmap of dompack.qSA('.organizations__googlemap')) {

  //     let iFrameLink = gmap.getAttribute("data-iframe");

  //     let iFrameHTML =
  //       `<iframe src="${iFrameLink}" width="100%" height="480"></iframe>`;

  //     gmap.insertAdjacentHTML("beforeend", iFrameHTML);
  //   }
}

function setupCookiewall(classNames) {
  for (const className of classNames) {

    for(const cookiewallObj of dompack.qSA(className)){
      cookiewallObj.classList.add(`iframe--cookiewalled`);

      let addHTML =
        `<div class="cookiewallcontainer">
           <p class="cookiewalltext">
             Om deze embedded content (bijv. video's) te zien, moet je marketing-cookies accepteren.
           </p>
           <div class="cookiewallbutton">
             <a class="button button--filled js-open-cookie-prefs">Pas je cookievoorkeuren aan</a>
           </div>
         </div>`;

      cookiewallObj.insertAdjacentHTML('beforeend', addHTML);
    }
  }
}
