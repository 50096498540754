import * as dompack from 'dompack';
import { RPCFormBase, registerHandler } from '@mod-publisher/js/forms';

class SendPDFForm extends RPCFormBase {
  constructor(node) {
    super(node);

    alert('wordt dit gebruikt?');

    if (dompack.debugflags['debug'])
      this.invokeRPC('debugprefill');
  }
}

dompack.onDomReady(() => {
});

registerHandler('slootsmid:sendpdfform', node => new SendPDFForm(node));
