/* global $ */
// import swal from 'sweetalert2';

export const bpFilterSidebar = 767; // keep in sync with var '$filters-sidebar' vars.scss
export const bpForceSmallHeader = 480; // keep in sync with var '$bp-force-small-header' vars.scss

// export function triggerErrorDialog(title, text, errorDetails) {
//   if (errorDetails)
//     console.error(errorDetails);

//   swal.fire({
//     title: title,
//     html: text,
//     type: 'error',
//   });
// }

// export function triggerGeneralErrorDialog(err) {
//   triggerErrorDialog('Foutmelding', 'Er ging iets mis bij het verwerken van de actie. Probeer het opnieuw of neem contact met ons op als het probleem zich blijft voordoen.', err);
// }

export function timeoutPromise(ms) {
  if (document.documentElement.classList.contains('dompack--debug-notimeoutpromise'))
    ms = 1;

  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function addMinimumResolveTime(promise, time) {
  let timeout = timeoutPromise(time);
  let result = await promise;
  await timeout;
  return result;
}

export function getScrollY() {
  return window.scrollY || window.pageYOffset || document.body.scrollTop;
}

export function formatDistance(distance) {
  // Convert from meters to km
  distance = String(distance / 1000);

  // Leave at least two significant digits and at most one decimal
  let parts = distance.split(".");
  if (parts.length == 1 || parts[0].length > 1 || parts[1].substr(0, 1) == "0")
    return `${parts[0]} km`;
  return `${parts[0]}.${parts[1].substr(0, 1)} km`;
}

export function getCookieBarCookieName() {
  return 'slootsmidPrefs';
}

export function testBreakPoint(testBp) {
  let screenWidth = $(window).width();

  if (testBp == 'sm')
    return screenWidth <= 576;
  else if (testBp == 'md')
    return screenWidth <= 768;
  else if (testBp == 'lg')
    return screenWidth <= 1034;

  console.error('unknown breakpoint', testBp);

  return false;
}
