// Auto-generated RPC interface from /opt/whdata/installedmodules/slootsmid.20240405T124633.344Z/webdesigns/slootsmid/shared/js/services.rpc.json
var RPCClient = require("@mod-system/js/wh/rpc").default;
var request = exports.rpcclient = new RPCClient("slootsmid:rpc");
exports.rpcResolve = function (promise, result) { request._handleLegacyRPCResolve(promise, result) };
exports.invoke = function () { return request.invoke.apply(request,Array.prototype.slice.call(arguments)); }

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function () { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function () { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function () { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function () { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function () { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function () { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function () { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/whdata/installedmodules/slootsmid.20240405T124633.344Z/lib/rpc.whlib'

exports.filterProducts = exports.FilterProducts = /*INTEGER ARRAY*/function (/*RECORD ARRAY*/ allproducts, /*RECORD*/ filters, /*RECORD ARRAY*/ filtertypes)
{
return request.invoke.apply(request,["FilterProducts"].concat(Array.prototype.slice.call(arguments)));
}

exports.downloadPDF = exports.DownloadPDF = /*RECORD*/function (/*INTEGER*/ fileid, /*RECORD*/ userdata)
{
return request.invoke.apply(request,["DownloadPDF"].concat(Array.prototype.slice.call(arguments)));
}

exports.getAllDealers = exports.GetAllDealers = /*RECORD ARRAY*/function (/*INTEGER*/ fileid)
{
return request.invoke.apply(request,["GetAllDealers"].concat(Array.prototype.slice.call(arguments)));
}

exports.searchDealersWithinBounds = exports.SearchDealersWithinBounds = /*RECORD*/function (/*INTEGER*/ fileid, /*RECORD*/ sw, /*RECORD*/ ne, /*INTEGER*/ zoomlevel, /*INTEGER*/ maxresults, /*RECORD*/ referencelatlng)
{
return request.invoke.apply(request,["SearchDealersWithinBounds"].concat(Array.prototype.slice.call(arguments)));
}

exports.getDealer = exports.GetDealer = /*RECORD*/function (/*INTEGER*/ fileid, /*INTEGER*/ dealerid, /*RECORD*/ latlng)
{
return request.invoke.apply(request,["GetDealer"].concat(Array.prototype.slice.call(arguments)));
}
