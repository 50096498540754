#main-menu {
  margin: 0;
  float: right;
  background: #fff;
}
@media (min-width: 1024px) {
  #main-menu > li > ul::before,
  #main-menu > li > ul::after {
    display: none !important;
  }
}
#main-menu .sub-arrow {
  position: absolute;
  right: 0;
}
#main-menu > li {
  margin-left: 25px;
}
@media screen and (max-width: 1060px) {
  #main-menu > li {
    margin-left: 15px;
  }
}
#main-menu > li a {
  font-size: 18px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
  text-decoration: none;
  color: var(--color-black) !important;
  text-transform: initial;
}
html.non-touch #main-menu > li a:hover {
  color: #6b8f00;
}
#main-menu > li:last-child {
  margin-right: 0;
}
#main-menu > li:last-child > a {
  padding-right: 0;
}
#main-menu > li li a {
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--color-black);
  transition: background 0.3s;
}
#main-menu > li li a:hover {
  background: #eee;
}
#main-menu > li li a .sub-arrow {
  display: none;
}
#main-menu ul,
#main-menu li {
  z-index: 999999999 !important;
}
#main-menu .sub-arrow {
  border: 0;
  width: 11px;
  height: 6px;
  background: url("~@mod-slootsmid/webdesigns/slootsmid/web/img/arrow-down-green-small.svg") no-repeat;
  transform: translateX(6px);
}
html.mt #main-menu .sub-arrow {
  background-image: url("~@mod-slootsmid/webdesigns/slootsmid/web/img/arrow-down-gray-small.svg");
}
@media (max-width: 1023px) {
  #main-menu {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  #main-menu.sm-clean a.has-submenu {
    padding-right: 12px !important;
  }
}

.sm-clean {
  background: none;
  border-radius: 0;
  padding: 0;
}

.sm-clean a,
.sm-clean a:active,
.sm-clean a:focus,
.sm-clean a:hover,
.sm-clean a.highlighted {
  font-size: 16px;
  line-height: 100%;
  font-weight: 600;
  color: #fff;
  transition: color 0.3s;
}

.sm-clean a .sub-arrow {
  border-top-color: #fff;
  margin-top: -4px;
  transform: translateX(3px);
}

#main-menu,
#main-menu > .has-mega-menu {
  position: static !important;
}

#main-menu .mega-menu {
  margin-left: 0 !important;
  right: 0 !important;
  width: auto !important;
  max-width: none !important;
}

#main-menu > .has-mega-menu .scroll-up,
#main-menu > .has-mega-menu .scroll-down {
  margin-left: 0 !important;
}

/* clear any floats inside the mega menus */
#main-menu .mega-menu > li {
  overflow: auto;
}

/* reset mega menu list/link styles (which are inherited from the SmartMenus core/theme styles) */
/* simple columns inside the mega menus */
#main-menu .mega-menu .column-1-3 {
  padding: 0 20px;
}

@media (min-width: 1024px) {
  #main-menu .mega-menu .column-1-3 {
    float: left;
    width: 33.33%;
  }
}
/* Sample image placeholder */
.mega-menu-sample-image {
  margin: 0.83em 0;
  border: 1px solid;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

/* Mobile menu top separator */
#main-menu:before {
  content: "";
  display: block;
  height: 1px;
  font: 1px/1px sans-serif;
  overflow: hidden;
  background: #4b4a73;
}

@media (min-width: 1024px) {
  #main-menu:before {
    display: none;
  }
}
/* Mobile menu toggle button */
.main-menu-btn {
  float: right;
  margin: 10px 20px 10px 0;
  position: relative;
  display: inline-block;
  width: 29px;
  height: 29px;
  text-indent: 29px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html.mt .main-menu-btn {
  margin: 25px 20px 10px 0;
}
@media screen and (max-width: 480px) {
  html.mt .main-menu-btn {
    margin-right: 70px;
  }
}
html.mt.smallheader .main-menu-btn {
  margin-top: 13px;
}

/* hamburger icon */
.main-menu-btn-icon,
.main-menu-btn-icon:before,
.main-menu-btn-icon:after {
  position: absolute;
  top: 50%;
  left: 2px;
  height: 2px;
  width: 24px;
  background: var(--color-black);
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.main-menu-btn-icon:before {
  content: "";
  top: -7px;
  left: 0;
}

.main-menu-btn-icon:after {
  content: "";
  top: 7px;
  left: 0;
}

/* x icon */
#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
  height: 0;
  background: transparent;
}

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
  top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
  top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* hide menu state checkbox (keep it visible to screen readers) */
#main-menu-state {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/* hide the menu in mobile view */
#main-menu-state:not(:checked) ~ #main-menu {
  display: none;
}

#main-menu-state:checked ~ #main-menu {
  display: block;
}

@media (min-width: 1024px) {
  /* hide the button in desktop view */
  .main-menu-btn {
    position: absolute;
    top: -99999px;
  }
  /* always show the menu in desktop view */
  #main-menu-state:not(:checked) ~ #main-menu {
    display: block;
  }
}
@media (max-width: 1023px) {
  #main-menu {
    box-shadow: 0 2px 6px 0 rgba(107, 143, 0, 0.5);
  }
  #main-menu .sub-arrow {
    right: 25px;
  }
  #main-menu > li {
    margin-right: 25px;
  }
  #main-menu > li > a {
    padding-left: 13px;
  }
  #main-menu .menu__subcat a {
    padding-left: 0;
    padding-right: 0;
    border: 0;
  }
}